// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-portfolio-starter-src-pages-404-js": () => import("./../../../node_modules/gatsby-theme-portfolio-starter/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-starter-src-pages-404-js" */),
  "component---node-modules-gatsby-theme-portfolio-starter-src-pages-index-js": () => import("./../../../node_modules/gatsby-theme-portfolio-starter/src/pages/index.js" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-starter-src-pages-index-js" */),
  "component---node-modules-gatsby-theme-portfolio-starter-src-templates-post-list-js": () => import("./../../../node_modules/gatsby-theme-portfolio-starter/src/templates/post-list.js" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-starter-src-templates-post-list-js" */),
  "component---node-modules-gatsby-theme-portfolio-starter-src-templates-tag-posts-js": () => import("./../../../node_modules/gatsby-theme-portfolio-starter/src/templates/tag-posts.js" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-starter-src-templates-tag-posts-js" */),
  "component---node-modules-gatsby-theme-portfolio-starter-src-templates-tags-page-js": () => import("./../../../node_modules/gatsby-theme-portfolio-starter/src/templates/tags-page.js" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-starter-src-templates-tags-page-js" */)
}

